// src/components/googleAdsense.js

import React, { useEffect } from 'react'

export const Adsense = ({ path }) => {
  useEffect(() => {
    console.log('Adsense se ha cargado en la web');
    ;(window.adsbygoogle = window.adsbygoogle || []).push({})
  }, [path])
  
  return (
    <ins 
      className="adsbygoogle"
      style={{ "display": "block" , textAlign: "center"  }}
      data-ad-client="ca-pub-8025817157840631"
      data-ad-slot="4262608200"
      data-ad-format="auto"
      data-full-width-responsive="true"
    />
  )
}